import React, { useState, useEffect } from 'react'
import Sidebar from '../Sidebar';
import MobileSidebar from '../MobileSidebar';
import { db } from '../../firebase';
import moment from 'moment';
import { collection, onSnapshot, query, orderBy, deleteDoc, doc } from 'firebase/firestore';
//MUI
import { useMediaQuery, Card, CardContent, Button, CardHeader } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router';



const columns = [
    { field: 'name', headerName: 'Name', width: 230 },
    { field: 'answer', headerName: 'Svar', width: 220 },
    { field: 'date', headerName: 'Dato', width: 220 },
    // {
    //     field: 'usedInCase',
    //     headerName: 'Brukt i sak',
    //     width: 150,
    //     renderCell: RenderSwitchCell,
    // },
    // {
    //     field: 'tipsHandler',
    //     headerName: 'Tips behandler',
    //     width: 230,
    //     renderCell: RenderCell,
    // },
    {
        field: 'Button',
        headerName: 'Til svar',
        width: 120,
        renderCell: ToAnswerButton,
    },
    {
        field: 'Slett',
        headerName: 'Slett',
        width: 120,
        renderCell: RenderButtonCell,
    },

];


function RenderButtonCell(params) {
    const id  = params.row.id;
    const handleDelete =  async() => {
        try {
            await deleteDoc(doc(db, "crosswords", id));
        } catch (error) {
            console.log("Det var en feil ved sletting av dokument ", error, " Prøv igjen eller kontakt Systemansvarlig")
        }
    };
    return (
        <Button onClick={handleDelete} color="error">Slett</Button>
    );
}



function ToAnswerButton(params) {
    const navigate = useNavigate()
    const { id } = params;
    const handleRedirect = () => {
        navigate(`../crossword/${id}`)
        console.log(`crossword/${id}`)
    };
    return (
        <Button onClick={handleRedirect} color="primary">Til Svar</Button>
    );
}

const Crossword = () => {
    const isPhone = useMediaQuery('(max-width:990px)');
    const [data, setData] = useState([])


    useEffect(() => {
        const ref = collection(db, "crosswords");
       const q = query(ref, orderBy("date", "desc"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const data = snapshot.docs.map((doc) => {
                let date = doc.data().date ? moment(doc.data().date.toDate()).format('DD/MM/YYYY HH:mm:ss') : moment().format('DD/MM/YYYY HH:mm:ss');
                return { id: doc.id, ...doc.data(), date };
            });
            setData(data);
        });
        return () => {
            unsubscribe();
        };
    }, []);

    console.log(data)

    return (
        <div className='dashboard-main'>
            {isPhone ? (
                <MobileSidebar /> // Show this component on phones
            ) : (
                <Sidebar className="sidebar-menu" />
            )}
            <div className='dashboard-main-content'>

                {/**          <Card sx={{ width: 500 }}>
                    <CardHeader
                        title="Kryssord"
                        subheader="Rediger kryssord"
                    />
                    <CardContent>

                        <TextField
                            id=""
                            size='small'
                            label="Ukas Ord"
                            value={thisWeeksWord}
                            onChange={(e) => { setThisWeeksWord(e.target.value) }}

                        />
                        <Button>
                            Lagre
                        </Button>
                    </CardContent>
                </Card>
             */}

                <Card sx={{ mt: 5, mb: 10, }}>
                    <CardHeader
                        title="Kryssord"
                        subheader="Se innsende svar"
                    />
                    <CardContent>
                        <DataGrid
                            rows={data}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            disableRowSelectionOnClick
                        />
                    </CardContent>
                </Card>
            </div>
            
        </div>
    )
}

export default Crossword


