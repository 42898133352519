import { createContext, useContext, useEffect, useState } from "react";
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from "../firebase";
import axios from 'axios';

const EmailContext = createContext()
export const EmailContextProvider = ({ children }) => {
    const [users, setUsers] = useState()
    useEffect(() => {
        const usersRef = collection(db, 'users');
        const unsub = onSnapshot(usersRef, (snapshot) => {
            const usersData = [];
            snapshot.forEach((doc) => {
                usersData.push({ id: doc.id, ...doc.data() });
            });
            setUsers(usersData);
        });

        return () => {
            unsub();
        };
    }, []);

    const sendTipAssigned = async (name, tipid, userMail,) => {

        const user = users.find((user) => user.name === name);
        let postData;
        const paper = process.env.REACT_APP_PAPER

        console.log("paper", paper)

        if (user && userMail === user.email) {
            console.log("User is the same");
        } else if (user) {
            const { email } = user;
            postData = {
                to: email,
                id: tipid,
                name: name,
                paper:paper,
            };
            sendTipCall(postData);
        } else {
            console.log("User not found for name: ", name);
        }

    }


    const sendTipCall = async (postData) => {
        try {
            //const response = await axios.post('https://api.tipsight.no/api/send-owner-change-email', postData);
            const response = await axios.post('http://localhost:5000/api/send-owner-change-email', postData);
            console.log('API response:', response.data);
        } catch (error) {
            console.error('Error making API call:', error);
        }
    }

    const sendNoteAdded = async (email, tipid, notes) => {
        const user = users.find((user) => user.email === email);
        let postData
        if (user) {
            const { name } = user;
            postData = {
                to: email,
                id: tipid,
                name: name,
                note: notes
            };
        }



        try {
            const response = await axios.post('https://api.tipsight.no/api/note-added', postData);
            console.log('API response:', response.data);
        } catch (error) {
            console.error('Error making API call:', error);
        }
    }

    return (
        <EmailContext.Provider value={{ sendTipAssigned, sendNoteAdded }}>
            {children}
        </EmailContext.Provider>
    );
}

export const EmailSender = () => {
    return useContext(EmailContext)
};