import React, { useState, useEffect } from 'react'
import Sidebar from '../Dashboard/Sidebar'
import { getStorage, ref, deleteObject, getMetadata, getDownloadURL } from "firebase/storage";
import Typography from '@mui/material/Typography'
import { Card, Stack, Divider, Box, Autocomplete, Switch, Button, TextField, Skeleton, useMediaQuery, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
//legg til i liste neste gang
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { db } from '../firebase'
import { doc, updateDoc, onSnapshot, collection, deleteDoc } from 'firebase/firestore'
import { useParams, useNavigate } from 'react-router'
import EditableChip from './EditComps/EditableChip'
import MobileSidebar from '../Dashboard/MobileSidebar'

const storage = getStorage();

const OneTips = () => {
  const Navigate = useNavigate()
  const isPhone = useMediaQuery('(max-width:600px)');
  const { id } = useParams()
  const [data, setData] = useState({})
  const [category, setCategory] = useState([])
  const [usedInCase, setUsedInCase] = useState()
  const [deletePopup, setDeletePopup] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState("")
  const [linkToCase, setLinkToCase] = useState("")
  const [isVideo, setIsVideo] = useState(false);
  const [mediaUrl, setMediaUrl] = useState('');
  const [notes, setNotes] = useState("")


  useEffect(() => {
    const documentRef = doc(db, 'tips', id);
    const tipscategoryRef = collection(db, "tipscategory");

    const unsubscribeCat = onSnapshot(tipscategoryRef, (snapshot) => {
      setCategory(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });

    const unsubscribe = onSnapshot(documentRef, async (documentSnapshot) => {
      if (documentSnapshot.exists()) {
        const data = documentSnapshot.data();
        setData(data);
        setUsedInCase(data.usedInCase)
        setNotes(data.notes)
        if (data.path) {
          if (Array.isArray(data.path)) {
            const urls = await Promise.all(data.path.map(async (path) => {
              const fileRef = ref(storage, path);
              try {
                const url = await getDownloadURL(fileRef);
                const metadata = await getMetadata(fileRef);
                setIsVideo(metadata.contentType.startsWith('video'));
                console.log("Download URL:", url);
                console.log("Metadata:", metadata);
                return url;
              } catch (error) {
                console.error('Error fetching file info:', error);
                return null;
              }
            }));
            setMediaUrl(urls.filter(url => url !== null));
          } else {
            console.log("File path:", data.path);
            const fileRef = ref(storage, data.path);
            try {
              const url = await getDownloadURL(fileRef);
              setMediaUrl(url);
              console.log("Download URL:", url);
              const metadata = await getMetadata(fileRef);
              setIsVideo(metadata.contentType.startsWith('video'));
              console.log("Metadata:", metadata);
            } catch (error) {
              console.error('Error fetching file info:', error);
            }
          }
        } else {
          setMediaUrl(data.imageUrl);
        }

      } else {
        Navigate("/tips");
      }
    });

    return () => {
      unsubscribe();
      unsubscribeCat();
    };
  }, [id, Navigate])

  console.log(id)

  console.log("medieurl", mediaUrl)

  const handleToggle = async (event) => {
    const toggleValue = event.target.checked
    setUsedInCase(toggleValue)
    try {
      const docRef = doc(db, 'tips', id);

      await updateDoc(docRef, { usedInCase: toggleValue, link: linkToCase, });
      console.log('Document updated successfully. 100');
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };



  const handlePayout = async (event) => {
    const toggleValue = event.target.checked
    //setUsedInCase(toggleValue)
    try {
      const docRef = doc(db, 'tips', id);
      await updateDoc(docRef, {
        payout: toggleValue,
      });
      console.log('Document updated successfully');
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };


  const handleReToFjt = () => {
    window.open(`${data.link}`, '_blank');
  }



  const updateCategoryDoc = async (value) => {
    try {
      const docRef = doc(db, 'tips', id);
      await updateDoc(docRef, { category: value });
      console.log('Document updated successfully! 200');
    } catch (error) {
      console.error('Error updating document:', error);
    }
  }

  const updateNotes = async (value) => {
    try {
      const docRef = doc(db, 'tips', id);
      await updateDoc(docRef, { notes: notes });
      console.log('Document updated successfully! 200');
      // sendNoteAdded(user.email, id, notes)
    } catch (error) {
      console.error('Error updating document:', error);
    }
  }


  const handleClose = () => {
    setDeletePopup(false);
  };

  const handleDeleteTips = async () => {
    if (!data.imageName || data.imageName.length > 0) {
      const desertRef = ref(storage, `tips/${data.imageName}`);
      deleteObject(desertRef).then(() => {
        console.log("det gikk fint å seltte bilde")
      }).catch((error) => {
        console.log("feil ved sletting av bilde", error)
      });
    }
    try {
      await deleteDoc(doc(db, "tips", id));
      Navigate("/tips")
    } catch (error) {
      console.log(error)
    }

  }

  const downloadMedia = async () => {
    for (const media of mediaUrl) {
      try {
        const response = await fetch(media);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        // Sjekk MIME-type fra responsen
        const contentType = response.headers.get('content-type');
        const blob = await response.blob();
        const link = document.createElement('a');
        const blobUrl = window.URL.createObjectURL(blob);
        link.href = blobUrl;

        // Bruk MIME-type for å bestemme filtypen
        if (contentType.includes('video')) {
          link.download = 'downloaded_video.mp4';
        } else if (contentType.includes('image')) {
          link.download = 'downloaded_image.jpg';
        } else {
          link.download = 'downloaded_file';
        }

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error('Error getting download URL:', error);
      }
    }
  };



  console.log(data)



  return (
    <div className='onetips-main'>
      {isPhone ? (
        <MobileSidebar /> // Show this component on phones
      ) : (
        <Sidebar className="sidebar-menu" />
      )}
      <div className='onetips-main-container'>
        {data && Object.keys(data).length > 0 ? (
          <div className='onetips-main-content'>
            <Typography variant="h4" color="primary">Tips</Typography>
            <br />
            <div className='onetips-stack-row'>
              <div>
                <div className='onetips-stack'>
                  {mediaUrl && (
                    Array.isArray(mediaUrl) ? (
                      <ImageList sx={{ width: 500, height: 300 }} cols={2} rowHeight={164}>
                        {mediaUrl.map((item, index) => (
                          <ImageListItem key={index}>
                            <img
                              srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                              src={`${item}?w=164&h=164&fit=crop&auto=format`}
                              alt="Det er noe feil ved inhenting av bilde"
                              loading="lazy"
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    ) : (
                      isVideo ? (
                        <video controls src={mediaUrl} className='onetips-tip-video'>
                          Video mangler
                        </video>
                      ) : (
                        <img src={mediaUrl.item} alt="Bilde er ikke sendt inn eller mangler" className='onetips-tip-img' />
                      )
                    )
                  )}
                  <Box className="onetips-info-container">
                    <Typography variant="h6" color="primary">Info om sak</Typography>
                    <Typography color="initial">{data.description}</Typography>
                    <br />
                    <Typography color="initial">{data.where}</Typography>
                  </Box>
                  <Divider />
                  <Box className="onetips-info-container ekstra-space">
                    <Typography variant="h6" color="primary">Kontakt informasjon</Typography>
                    <Typography color="initial">Navn : {data.name}</Typography>
                    <Typography color="initial">Telefon nr : {data.phone}</Typography>
                  </Box>
                </div>
              </div>

              {category.length > 0 && Object.keys(data).length > 0 ? (
                <div className='onetips-Right-sidebar'>
                  <Card sx={isPhone ? { width: 300, height: "maxContent" } : { width: 350, height: "maxContent" }}>
                    <Box sx={{ padding: 2 }}>
                      <Typography variant="h6" color="primary">Tips instillinger / notater</Typography>
                      <Typography color="initial">Tips behandler</Typography>
                      <EditableChip id={id} />
                      <Divider
                        variant="fullWidth"
                        orientation="horizontal"
                        sx={{ marginTop: 1.5, marginBottom: 1 }}
                      />
                      <Typography color="initial">Brukt i sak</Typography>
                      <Switch
                        value="false"
                        checked={usedInCase}
                        onChange={handleToggle}
                        inputProps={{ "aria-label": '' }}

                      />
                      {usedInCase ? (
                        <Button onClick={handleReToFjt}>til avis</Button>
                      ) : (
                        <TextField label="Link til avis" onChange={(e) => { setLinkToCase(e.target.value) }} />
                      )}
                      <Divider
                        variant="fullWidth"
                        orientation="horizontal"
                        sx={{ marginTop: 1, marginBottom: 1 }}
                      />
                      <Typography color="initial" sx={{ marginBottom: 2 }}>Kategori</Typography>
                      <Autocomplete
                        value={selectedCategory}
                        onChange={(event, newValue) => {
                          setSelectedCategory(newValue);
                          updateCategoryDoc(newValue)
                        }}
                        id="controllable-states-demo"
                        options={category.map((option) => option.category)}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="velg kategori" sx={{ width: 260 }} />}
                      />
                      <Divider
                        variant="fullWidth"
                        orientation="horizontal"
                        sx={{ marginTop: 1, marginBottom: 1 }}
                      />

                      <Typography color="initial" sx={{ marginBottom: 2 }}>Last ned bilde her</Typography>
                      <Button sx={{ width: "100%", marginTop: 2, }} onClick={downloadMedia} variant='outlined'>Last ned media</Button>


                      {data.hasOwnProperty('payout') ? (
                        <div>
                          <Divider
                            variant="fullWidth"
                            orientation="horizontal"
                            sx={{ marginTop: 2, marginBottom: 1 }}
                          />
                          <Typography color="initial" sx={{ marginBottom: 2 }}>Honorar</Typography>
                          <Switch
                            value="false"
                            checked={data.payout}
                            onChange={handlePayout}
                            inputProps={{ "aria-label": '' }}
                          />
                        </div>
                      ) : (<></>)}

                      <Divider
                        variant="fullWidth"
                        orientation="horizontal"
                        sx={{ marginTop: 2, marginBottom: 1 }}
                      />
                      <Typography color="initial" sx={{ marginBottom: 2 }}>Saksnotater</Typography>



                      <TextField
                        sx={{ width: "100%" }}
                        id="outlined-multiline-static"
                        label="Her er det som har skjedd"
                        multiline
                        rows={6}
                        value={notes}
                        onChange={(e) => { setNotes(e.target.value) }}
                      />
                      <Button sx={{ width: "100%", marginTop: 2, }} onClick={updateNotes}>Lagre notat</Button>
                      <Button sx={{ width: "100%", marginTop: 5, }} variant='outlined' color='error' onClick={() => { setDeletePopup(true) }}>Slett tips</Button>
                      {/*  <Button onClick={handleSendMail}>Send mail</Button> */}
                    </Box>
                  </Card>
                </div>
              ) : (
                <div>
                  <Stack spacing={1}>
                    <Skeleton variant="text" sx={{ fontSize: '4rem' }} width={210} />
                    <Skeleton variant="rectangular" width={300} height={60} />
                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={210} />
                    <Skeleton variant="rounded" width={300} height={60} />
                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={210} />
                    <Skeleton variant="rounded" width={300} height={120} />
                  </Stack>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div>
              <Stack spacing={1}>
                <Skeleton variant="text" sx={{ fontSize: '4rem' }} width={210} />
                <Skeleton variant="rectangular" width={350} height={350} />
                <Skeleton variant="rounded" width={300} height={60} />
                <Skeleton variant="rounded" width={300} height={100} />
              </Stack>
            </div>
          </div>
        )}
      </div>


      <Dialog
        open={deletePopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Slett tips"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Du er i ferd med å slette ett tips, er du sikker? Det vil ikkje være mulig å gjennopprette.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='outlined'>Avbryt</Button>
          <Button onClick={handleDeleteTips} variant='contained' autoFocus>
            Slett tips
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );

}

export default OneTips